@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poppins", sans-serif;
}
.ant-table-cell {
  background-color: white !important;
}
.ant-table-cell::before {
  background-color: white !important;
}
.ant-table-thead {
  border-bottom: 2px solid gray !important;
}
.ant-table-wrapper .ant-table-container table > thead > tr:first-child > * {
  border-bottom: 2px solid #e7e7e7 !important;
}

.icon {
  transition: transform 0.2s ease-in-out;
}

.icon:hover {
  transform: scale(1.1); /* You can adjust the scaling factor as needed */
}

.container {
  padding: 10px;
}

/* Code By Webdevtrick ( https://webdevtrick.com ) */
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
.container {
  font-family: "Lato", sans-serif;
  /* width: 100vh; */
  margin: 0 auto;
}

.simulationDiv{
  width: 100%;
}

.outletcontaonner {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 45px;
}

.wrapper {
  display: block;
  /* height: 400px; */
  vertical-align: middle;
}



.nav {
  margin-top: 40px;
}
.pull-right {
  float: right;
}
/* a, a:active {
	color: #212121;
	text-decoration: none;
}
a:hover {
	color: #999;
} */
.arrow-steps .step {
  font-size: 14px;
  text-align: center;
  color: #fff;
  width: 250px;
  box-shadow: 1px 2px 3px 0px #181414;

  cursor: default;
  margin: 7px 3px;
  padding: 10px 10px 10px 30px;
  min-width: 180px;
  float: left;
  position: relative;

  background-color: #72cccc;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
}
.arrow-steps .step:after,
.arrow-steps .step:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -17px;
  width: 0;
  height: 0;

  border-top: 19px solid transparent;
  border-bottom: 23px solid transparent;
  border-left: 18px solid #72cccc;
  z-index: 2;
  transition: border-color 0.2s ease;
}
.arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 17px solid #edebf2;
  z-index: 0;
}
.arrow-steps .step:first-child:before {
  border: none;
}
.arrow-steps .step:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.arrow-steps .step span {
  position: relative;
}
.arrow-steps .step span:before {
  opacity: 0;
  content: "✔";
  position: absolute;
  top: -2px;
  left: -20px;
  color: #0c2b44;
}
.arrow-steps .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}
.arrow-steps .step.current {
  color: #fff;
  background-color: #032B43;
}
.arrow-steps .step.current:after {
  border-left: 17px solid #032B43;
}
@media (max-width: 765px) {
  .arrow-steps .step {
    min-width: 35px;
  }
}

.table-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.ant-table {
  flex: 1;
  overflow: auto;
}


/* Define a class for the table container */
.table-container {
  width: 100%; /* Make the table container take the full width of its parent */

}
  /* Define the initial width for the column */
  .ant-table-thead th:first-child, .ant-table-tbody td:first-child {
    max-width: 350px;
  }

  /* Use media queries to adjust the column width based on screen size */
  @media (max-width: 768px) {
    .ant-table-thead th:first-child, .ant-table-tbody td:first-child {
      max-width: 200px; /* Adjust the width for smaller screens */
    }
  }

  @media (max-width: 480px) {
    .ant-table-thead th:first-child, .ant-table-tbody td:first-child {
      max-width: 350px; /* Adjust the width for even smaller screens */
    }
  }



/* .ant-modal .ant-modal-content {
    position: relative;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
    padding: 20px 0px 0px 0px;
} */


.checkmark {
  display: inline-block;
  margin-right: 5px;
  font-size: 12px;
  font-weight: bold;
  background-color: white;
  color: #0c2b44;
  border-radius: 50%;
  padding: 1px 4px;
}

.checkmark {
  margin-right: 5px;
}

.step-number {
  display: inline-block;
  width: 20px;
  height: 20px;
  color: #fff;
  background-color: #24013f;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
}

.ant-tabs-tab:hover {
  color: #0c2b44 !important;
}

.ant-tabs .ant-tabs-tab-btn:active .ant-tabs-tab-remove:active {
  color: #fff;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0c2b44;
  text-shadow: 0 0 0.25px #0c2b44;
}

.ant-tabs-ink-bar {
  background: #0c2b44 !important;
}

@keyframes move-up6 {
  to {
    transform: translateY(-10px);
  }
}

@keyframes move-down1 {
  to {
    transform: translateY(10px);
  }
}

/* @layer utilities {
  .animate-move-up6 {
    animation: move-up6 2s ease-in infinite alternate-reverse;
  }

  .animate-move-down1 {
    animation: move-down1 2s ease-in infinite alternate-reverse;
  }
} */



/* Media query for screens with a maximum width of 640px (sm screens) */
/* @media screen and (max-width: 640px) {
  .tradingview-widget-container {
   overflow-x: overlay;
   width: 100% !important;
   height: fit-content;
  }
} */

#pricing-table {
  margin: 100px;
  text-align: center;
}

#pricing-table .plan {
  background: #fff;
  border: 1px solid #ddd;
  color: #333;
  padding: 20px;
  position: relative;
}

#pricing-table #most-popular {
  z-index: 2;
  top: -13px;
  padding: 30px 20px;
  border-radius: 5px;
  -moz-box-shadow: 18px 14px 7px -14px #0c2b44, -18px -14px 6px -14px #0c2b44;
  -webkit-box-shadow: 18px 14px 7px -14px #0c2b44, -18px -14px 6px -14px #0c2b44;
  box-shadow: 18px 14px 7px -14px #0c2b44, -18px -14px 6px -14px #0c2b44;
}

#pricing-table .plan:nth-child(1) {
  -moz-border-radius: 5px 0 0 5px;
  -webkit-border-radius: 5px 0 0 5px;
  border-radius: 5px 0 0 5px;
}

#pricing-table .plan:nth-child(4) {
  -moz-border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
}

/* --------------- */

#pricing-table h3 {
  font-size: 20px;
  font-weight: normal;
  padding: 20px;
  margin: -20px -20px 50px -20px;
  background-color: #eee;
  background-image: -moz-linear-gradient(#fff, #eee);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#eee)
  );
  background-image: -webkit-linear-gradient(#fff, #eee);
  background-image: -o-linear-gradient(#fff, #eee);
  background-image: -ms-linear-gradient(#fff, #eee);
  background-image: linear-gradient(#fff, #eee);
}

#pricing-table #most-popular h3 {
  background-color: #0c2b44;
  background-image: -moz-linear-gradient(#eee, #ddd);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#eee),
    to(#ddd)
  );
  background-image: -webkit-linear-gradient(#eee, #ddd);
  background-image: -o-linear-gradient(#eee, #ddd);
  background-image: -ms-linear-gradient(#eee, #ddd);
  background-image: linear-gradient(#eee, #ddd);
  margin-top: -30px;
  padding-top: 30px;
  -moz-border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}

#pricing-table .plan:nth-child(1) h3 {
  -moz-border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  border-radius: 5px 0 0 0;
}

#pricing-table .plan:nth-child(4) h3 {
  -moz-border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  border-radius: 0 5px 0 0;
}

#pricing-table h3 span {
  display: block;
  font: bold 25px/100px Georgia, Serif;
  color: #0c2b44;
  background: #212121;
  border: 5px solid #fff;
  height: 100px;
  width: 100px;
  margin: 10px auto -65px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  -moz-box-shadow: 0 5px 20px #212121 inset, 0 3px 0 #212121 inset;
  -webkit-box-shadow: 0 5px 20px #212121 inset, 0 3px 0 #212121 inset;
  box-shadow: 0 5px 20px #212121 inset, 0 3px 0 #212121 inset;
}

/* --------------- */

#pricing-table ul {
  margin: 20px 0 0 0;
  padding: 0;
  list-style: none;
}

#pricing-table li {
  border-top: 1px solid #ddd;
  padding: 10px 0;
}

/* --------------- */

#pricing-table .signup {
  position: relative;
  padding: 8px 20px;
  margin: 20px 0 0 0;
  color: #000;
  font: bold 14px Arial, Helvetica;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  background-color: #0c2b44;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5), 0 2px 0 rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5),
    0 2px 0 rgba(0, 0, 0, 0.7);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5), 0 2px 0 rgba(0, 0, 0, 0.7);
}

#pricing-table .signup:hover {
  background-color: #000;
  color: #0c2b44;
}

#pricing-table .signup:active,
#pricing-table .signup:focus {
  background: #0c2b44;
  top: 2px;
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.7) inset;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.7) inset;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.7) inset;
}

.ant-drawer .ant-drawer-body {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 0%;
}

.sub-menu {
  display: none;
}

.sub-menu.open {
  display: block;
}

/* Add this to handle hover for the main menu item */
.menu-item:hover .sub-menu {
  display: block;
}

/* Glassmorphism Card Styles */
.glassmorphism-card {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.glassmorphism-card:hover {
  transform: scale(1.05);
}

/* Glassmorphism Card Styles END */
.nm-concave-blue-200-sm {
  background: linear-gradient(145deg, #ebebeb, #fff);
  box-shadow: 0.1em 0.1em 0.2em #556d86, -0.1em -0.1em 0.2em #032b43;
}
.nm-concave-yellow-200-sm {
  background: linear-gradient(145deg, #ebebeb, #fff);
  box-shadow: 0.1em 0.1em 0.2em #556d86, -0.1em -0.1em 0.1em #ffba08;
}
.nm-concave-lblue-200-sm {
  background: linear-gradient(145deg, #ebebeb, #fff);
  box-shadow: 0.1em 0.1em 0.2em #59c3c3, -0.1em -0.1em 0.1em #59c3c3;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: blue;
}

.apexcharts-menu-icon {
  display: none;
}

.sub-menu {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}

.sub-menu.open {
  max-height: 1000px;
}

.ant-tabs-nav .ant-tabs-nav-list {
  width: 250px;
  display: flex;
  justify-items: center;
}

.ant-input:placeholder-shown {
  text-overflow: ellipsis;
  background: transparent;
}

.ant-input {
  background-color: transparent !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.ant-tabs-nav .ant-tabs-nav-list {
  position: relative;
  display: flex;
  transition: opacity 0.3s;
  align-items: center;
  justify-content: center;
}

.Jainish button[aria-label="Close"] {
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
}

@media only screen and (max-width: 786px) {
  .mobilescroll {
    overflow-x: scroll;
  }
}

.custom-clip {
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}

.blur-background {
  background: linear-gradient(177deg, #9de0ff, #ffffff, #9de0ff);
  border-radius: 4px;
  padding: 15px;
  position: relative;
}

.blur_bg {
  background: rgb(231 229 229 / 85%);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  top: 0;
  bottom: 0;
  font-weight: 600;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1;
}
.blur_bg_text {
  position: absolute;
  left: 30%;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 2;
  font-size: 35px;
}
@media screen and (max-width: 767px) {
  .blur_bg_text {
    left: 22%;
    font-size: 27px;
  }
}

.cardswitch {
  backdrop-filter: blur(9px) saturate(99%);
  -webkit-backdrop-filter: blur(9px) saturate(99%);
  background-color: rgba(17, 25, 40, 0.49);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

.ant-modal-root .ant-modal-mask {
  position: fixed;
  inset: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.09);
  pointer-events: none;
}

/* .custom-ok-button {
    background-color: blue;
    color: white; 
} */
/* .ant-modal .ant-modal-content {
	top: 20vh !important;
} */

.flex_reverse {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .flex_reverse {
    flex-direction: column-reverse;
  }
}

.ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>tr>td, :where(.css-dev-only-do-not-override-nnuwmp).ant-table-wrapper .ant-table.ant-table-small tfoot>tr>th, :where(.css-dev-only-do-not-override-nnuwmp).ant-table-wrapper .ant-table.ant-table-small tfoot>tr>td {
    padding: 2px 8px !important;
}

@media screen and (max-width: 786px) {
	.table-newres{
		overflow-x: scroll;
	}
}

.ant-table-wrapper .ant-table-thead>tr>th,.ant-table-wrapper .ant-table-tbody>tr>th,.ant-table-wrapper .ant-table-tbody>tr>td,.ant-table-wrapper tfoot>tr>th,.ant-table-wrapper tfoot>tr>td {
    padding: 2px 16px;
}
.ant-tabs-card >.ant-tabs-nav .ant-tabs-tab-active{
  background: #69c3c4 !important;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 7px;
  height: 4px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #69c3c4;
}

/* Define your custom button style */
.custom-ok-button-class {
  background-color: #69c3c4; /* Change the background color as desired */
  color: #fff; /* Change the text color if needed */
  border: none; /* Remove the button border if desired */
  padding: 5px 25px;
  border-radius: 25px;
  /* Add any other custom styles you want */
}


.collapse-item {
  font-size: 10px; 
}


.ant-table-wrapper table{
  box-shadow: 9px 8px 10px #b8b9be, -3px -3px 7px #fff;
  background-color: #EBEBEB !important;
}


.ant-table-wrapper .ant-table-container table > thead > tr:first-child > * {
    border-bottom: 2px solid #e7e7e7 !important;
    background: #032B43 !important;
    color: #fff !important;
}

/* Your CSS stylesheet */
.on {
  background-color: #223e55 !important;
  color: white;
}

.off {
  background-color: #69c3c4 !important;
  color: white;
}


@media (max-width: 765px) {
  .arrow-steps .step {
    min-width: 35px;
  }
  .ant-tabs-nav .ant-tabs-nav-list {
    position: relative;
    display: flex;
    transition: opacity 0.3s;
    align-items: center;
    overflow-x: scroll;
    justify-content: center;
}
}

.tableact {
  box-shadow: -0.75em -0.75em 2.25em hsl(var(--hue),10%,30%), 0.75em 0.75em 2.25em hsl(var(--hue),5%,5%);
  /* box-shadow: -10px -10px 15px rgba(255,255,255,0.5), 10px 10px 15px rgba(70,70,70,0.15), inset -10px -10px 15px rgba(255,255,255,0.5), inset 10px 10px 15px rgba(70,70,70,0.15); */
}

.newtable{
      padding:17px 8px !important;
}



.rotate {
  animation: rotate 2s linear infinite; /* You can adjust the duration and other animation properties as needed */
}


@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.borderText{
  -webkit-text-stroke: 2px #454545;
  -webkit-text-fill-color: transparent;
  font-family: Rubik,sans-serif;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: .05em;
}


@media (max-width: 991px) {
  
.stickyexample{
  position: -webkit-fixed;
  position: fixed!important;
  top: 5rem;
  margin-bottom: 10rem;
}
}

.stickyexample{
  position: -webkit-sticky ;
  position: sticky;
  top: 5rem;
  margin-bottom: 2rem;
}

.sticky_navbar_guide{
  position: -webkit-sticky;
  position: sticky;
  top: 0rem;
}

html {
  scroll-behavior: smooth;
}

.custom-dropdown {
  max-height: 70px; /* Set the maximum height for the dropdown */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Add this to your CSS file */
.refreshing {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
